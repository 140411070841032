import { Config } from "./config";
import { getMainDomain } from "./helper";

let CDN_URL = Config.Cdn_Url;

if (
  typeof window !== "undefined" &&
  window?.location?.hostname !== "localhost"
) {
  CDN_URL = Config.Cdn_Url?.replace(
    getMainDomain(Config.Cdn_Url),
    getMainDomain(window?.location?.hostname)
  );
}
const liveRoomRelativePath = `${Config.CDN_URL}/liveroom`;
const levelIconFolderPath = `/assets/icons/level`;
const levelIconRelativePath = `${CDN_URL}/levels`;
const leaderBoardRelativePath = `${CDN_URL}/leaderboard`;
const earningsRelativePath = `${CDN_URL}/earnings`;
const DEV_earningsRelativePath = "/assets";
const astroIconFolderPath = `/assets/icons/astro`;

const CHINGARI_LOGO = "/assets/icons/chingari-logo.svg",
  IC_VIEWERS = "/assets/icons/viewers.svg",
  IC_PLUS = "/assets/icons/plus.svg",
  IC_EYE = "/assets/icons/eye-purple.svg",
  IC_GIFT = "/assets/icons/gift.svg",
  IC_CHAT = "/assets/icons/chat.svg",
  IC_GIFT_WHITE = "/assets/icons/gift-white.svg",
  IC_CHAT_WHITE = "/assets/icons/chat-white.svg",
  IC_ERROR = "/assets/icons/error.svg",
  IC_CALL = "/assets/icons/call-icon.svg",
  IC_CALL_FREE = "/assets/icons/call-icon-free.svg",
  IC_CROSS = "/assets/icons/cross.svg",
  IC_FIRE_ORANGE = "/assets/icons/fire-orange.svg",
  IC_MUTE = "/assets/icons/mute.svg",
  IC_UNMUTE = "/assets/icons/unmute.svg",
  IC_VCARD = "/assets/icons/VCard.svg",
  LEVEL_ICON = `${Config.CDN_URL}/levels/allLevels`,
  SOCIAL_BTN_APP_STORE = "/assets/icons/app-store.svg",
  SOCIAL_BTN_PLAY_STORE = "/assets/icons/play-store.svg",
  SOCIAL_BTN_APP_GALARY = "/assets/icons/app-galary.svg",
  SOCIAL_BTN_ANDROID = "/assets/icons/android.svg",
  DEFAULT_AVATAR = "/assets/icons/default-avatar.svg";
const SOME_IMG = "/assets/icons/someImg.svg";

const ERROR_IMAGE = "/assets/images/error.svg",
  NO_DATA = "/assets/images/no-data.svg",
  NETWORK_ERROR_IMAGE = "/assets/images/network-error.svg";

const PAGE_LINKS_ICONS = {
  LIVE_ROOMS: {
    icon: "/assets/icons/live-rooms.svg",
    activeIcon: "/assets/icons/live-rooms-active.svg",
  },
  AUDIO_ROOMS: {
    icon: "/assets/icons/audio-rooms.svg",
    activeIcon: "/assets/icons/audio-rooms-active.svg",
  },
  FEEDS: {
    icon: "/assets/icons/feeds.svg",
    activeIcon: "/assets/icons/feeds-active.svg",
  },
  EXPLORE: {
    icon: "/assets/icons/explore.svg",
    activeIcon: "/assets/icons/explore-active.svg",
  },
  LEADERBOARD: {
    icon: "/assets/icons/leaderboard.svg",
  },
};

const DOWNLOAD_ICONS: { [key: string]: string } = {
  IOS: "/assets/icons/ios.svg",
  ANDROID: "/assets/icons/android-store.svg",
  HUWAEI: "/assets/icons/huwaei.svg",
  PLAYSTORE: "/assets/icons/playstore.svg",
};

const ROOM_CONTAINER = {
  FAVICON: `${liveRoomRelativePath}/fav.png`,
  CHINGARI_LOGO: `${liveRoomRelativePath}/chingari-logo.webp`,
  FIRE_LOGO: `${liveRoomRelativePath}/chingari-fire-icon.svg`,
  USER: `${liveRoomRelativePath}/user.svg`,
  ARROW: `${liveRoomRelativePath}/arrow.svg`,
  PARTY: `${liveRoomRelativePath}/party.svg`,
  PLAY_STORE: `${liveRoomRelativePath}/play-store.webp`,
  APPLE_STORE: `${liveRoomRelativePath}/apple-store.webp`,
  DEFAULT_ROOM: `${liveRoomRelativePath}/default-room.webp`,
};

const CATEGORY_ICONS_DEV_URL = `/assets/icons`;

const CATEGORY_ICONS = {
  CATEGORY_IC_DATING: `${CATEGORY_ICONS_DEV_URL}/category_dating.svg`,
  CATEGORY_IC_ASTROLOGY: `${CATEGORY_ICONS_DEV_URL}/category_astrology.svg`,
  CATEGORY_IC_LEGAL: `${CATEGORY_ICONS_DEV_URL}/category_legal.svg`,
  CATEGORY_IC_HEALTH: `${CATEGORY_ICONS_DEV_URL}/category_health.svg`,
  CATEGORY_IC_FINANCE1: `${CATEGORY_ICONS_DEV_URL}/category_finance1.svg`,
  CATEGORY_IC_FINANCE2: `${CATEGORY_ICONS_DEV_URL}/category_finance2.svg`,
  CATEGORY_IC_STAR: `${CATEGORY_ICONS_DEV_URL}/category_star.svg`,
};
const LAYOUT_CDN_BASE_URL = "https://media.chingari.io/uploads/layout";

const DEFAULT_USER_AVATAR = `${LAYOUT_CDN_BASE_URL}/default-avatar.svg`;

const FILTER_ICON = "/assets/icons/FilterIcon.svg";

const allLevelIcons = (): { [key: string]: string } =>
  [...(Array(13) as [])].reduce(
    (acc: { [key: string]: string }, _elem, index: number) => {
      let idx = index;
      if (idx === 11) idx = 20;
      if (idx === 12) idx = 100;
      acc[`level${idx}`] = `${levelIconFolderPath}/allLevels/${idx}.svg`;
      acc[`badge${idx}`] = `${levelIconFolderPath}/badges/${idx}.svg`;
      return acc;
    },
    {}
  );

export const Images: { [key: string]: string } = {
  iconBack: "/assets/icons/back.svg",
  iconBackWhite: "/assets/icons/back-white.svg",
  iconCross: "/assets/icons/cross.svg",
  iconProfile: "/assets/icons/profile.svg",
  iconProfile1: "/assets/icons/profileIcon.svg",
  iconStar: `${leaderBoardRelativePath}/star.svg`,
  iconDiamond: `${leaderBoardRelativePath}/diamond.svg`,
  iconTwitterScore: "/assets/icons/twitter-score-badge.svg",
  iconBean: `/assets/icons/beanIcon.svg`,
  iconInfo: `/assets/icons/info.svg`,
  iconInfoWhite: `/assets/icons/info-white.svg`,
  iconInfoBlack: `assets/icons/infoBlack.svg`,
  iconInfoRed: `assets/icons/info-icon.svg`,
  iconAnonymous: `${leaderBoardRelativePath}/anonymous.svg`,
  iconCrownPlatinum: "/assets/icons/crown-platinum.svg",
  iconCrownGoldNew: "/assets/icons/crown-gold-new.svg",
  iconCrownSilverNew: "/assets/icons/crown-silver-new.svg",
  iconCrownBronzeNew: "/assets/icons/crown-bronze-new.svg",
  iconCrownGold: `${leaderBoardRelativePath}/crown-gold.svg`,
  iconCrownSilver: `${leaderBoardRelativePath}/crown-silver.svg`,
  iconCrownBronze: `${leaderBoardRelativePath}/crown-bronze.svg`,
  iconLoader: `/assets/icons/loader.svg`,
  levelCardBanner: "/assets/card_banner.svg",
  iconCallPrice: "/assets/icons/call_price.svg",
  iconEditPrice: "/assets/icons/edit_price_pencil.svg",
  iconForward: "/assets/icons/forwardArrow.svg",
  iconWarning: "/assets/icons/warning.svg",
  ...allLevelIcons(),
  iconCalendar: `/assets/icons/calendar-white.svg`,
  iconGariRound: "/assets/icons/gari.svg",
  iconGuac: "/assets/icons/guac.svg",
  iconJup: "/assets/icons/jup.svg",
  iconWen: "/assets/icons/wen.svg",
  iconFireOrange: "/assets/icons/fire-orange.svg",
  iconDoodoo: "/assets/icons/doodoo.svg",
  iconChewy: "/assets/icons/chewy.webp",
  iconSuccess: "/assets/icons/verified.svg",
  iconFailed: "/assets/icons/failed.svg",

  // livestream
  iconFavIcon: `${liveRoomRelativePath}/fav.png`,
  iconChingariLogo: `${liveRoomRelativePath}/chingari-logo.webp`,
  iconChingariFireLogo: `${liveRoomRelativePath}/chingari-fire-icon.svg`,
  iconUser: `${liveRoomRelativePath}/user.svg`,
  iconArrow: `${liveRoomRelativePath}/arrow.svg`,
  iconParty: `${liveRoomRelativePath}/party.svg`,
  iconPlayStore: `${liveRoomRelativePath}/play-store.webp`,
  iconAppleStore: `${liveRoomRelativePath}/apple-store.webp`,
  iconDefaultRoom: `${liveRoomRelativePath}/default-room.webp`,

  // earning

  infoIcon: `${earningsRelativePath}/Icons/info.svg`,
  iconBeanEarning: `${earningsRelativePath}/Icons/Bean_icon.svg`,
  iconRightCarrot: `/assets/icons/rightCarrotPurple.svg`,
  iconRightCarrotBlack: `/assets/icons/rightCarrotBlack.svg`,
  iconInvertedTriangle: `${earningsRelativePath}/Icons/invertedTriangle.svg`,
  iconDanger: `${earningsRelativePath}/Icons/danger.svg`,
  iconVerified: `${earningsRelativePath}/Icons/verified.svg`,
  iconGari: `${earningsRelativePath}/Icons/gari_logo.svg`,
  iconPaytm: `/assets/icons/Paytm_logo.svg`,
  iconClose: `${earningsRelativePath}/Icons/iconClose.svg`,
  iconCloseBlack: `/assets/icons/iconCloseBlack.svg`,
  iconHelp: `${earningsRelativePath}/Icons/Help.svg`,
  walletIcon: `/assets/icons/link-wallet-icon.svg`,

  // gifts

  giftSentBg: "/assets/images/gifts_sent.png",
  giftReceivedBg: "/assets/images/gifts_receive.png",
  giftImage: "/assets/images/gift.png",

  // diamonds
  diamondLarge: "/assets/icons/diamond_large.svg",
  filter: "/assets/icons/filterNew.svg",
  upArrow: "/assets/icons/upArrow.svg",
  downArrow: "/assets/icons/downArrow.svg",
  upArrowBlack: "/assets/icons/upArrowNew.svg",
  downArrowBlack: "/assets/icons/downArrowNew.svg",
  ballon: "/assets/backgrounds/Ballon.svg",

  // faq
  faqIcon: "/assets/images/faq.png",
  faqDownArrow: `/assets/icons/down_arrow.svg`,
  faqUpArrow: `/assets/icons/up_arrow.svg`,

  // creator form
  warningIcon: "/assets/icons/warning1.svg",
  successImg: "/assets/images/creator-form-success.svg",

  //astro
  leo: `${astroIconFolderPath}/Leo.svg`,
  aries: `${astroIconFolderPath}/Aries.svg`,
  taurus: `${astroIconFolderPath}/Taurus.svg`,
  cancer: `${astroIconFolderPath}/Cancer.svg`,
  aquarius: `${astroIconFolderPath}/Aquarius.svg`,
  libra: `${astroIconFolderPath}/Libra.svg`,
  scorpio: `${astroIconFolderPath}/Scorpio.svg`,
  sagittarius: `${astroIconFolderPath}/Sagittarius.svg`,
  capricorn: `${astroIconFolderPath}/Capricorn.svg`,
  pisces: `${astroIconFolderPath}/Pisces.svg`,
  gemini: `${astroIconFolderPath}/Gemini.svg`,
  virgo: `${astroIconFolderPath}/Virgo.svg`,
  name_card_background: `/assets/backgrounds/Name-Card.svg`,
  character: `${astroIconFolderPath}/alien.svg`,
  physical: `${astroIconFolderPath}/dumbell.svg`,
  family: `${astroIconFolderPath}/hearts-two.svg`,
  education: `${astroIconFolderPath}/briefcase.svg`,
  health: `${astroIconFolderPath}/Health.svg`,
  plane: `${astroIconFolderPath}/plane.svg`,
  diamond: `${astroIconFolderPath}/diamond.svg`,
  face_smile: `${astroIconFolderPath}/face-smile.svg`,
  search: `${astroIconFolderPath}/search-ic.svg`,
  sun: `${astroIconFolderPath}/sun.svg`,
  moon: `${astroIconFolderPath}/moon.svg`,
  astroImage1: `${astroIconFolderPath}/astro-image-1.svg`,
  astroImage2: `${astroIconFolderPath}/astro-image-2.svg`,
  astroImage3: `${astroIconFolderPath}/astro-image-3.svg`,
  diamondPurple: `${astroIconFolderPath}/diamond-purple.svg`,
  redDot: `${astroIconFolderPath}/red-dot.svg`,
  translate: `${astroIconFolderPath}/Translate.svg`,
  verifiedBadge: `${astroIconFolderPath}/verified-badge.svg`,
  astroImage4: `${astroIconFolderPath}/astro-image-4.svg`,
  astroImage5: `${astroIconFolderPath}/astro-image-5.svg`,
  kundliImage1: `${astroIconFolderPath}/kundli-image-1.svg`,
  kundliImage2: `${astroIconFolderPath}/kundli-image-2.svg`,
  kundliImage3: `${astroIconFolderPath}/kundli-image-3.svg`,
  IconSun: `${astroIconFolderPath}/sun-white.svg`,
  IconPanchang: `${astroIconFolderPath}/panchang-icon.svg`,
  IconKundli: `${astroIconFolderPath}/kundli-icon.svg`,
  IconFreeKundli: `${astroIconFolderPath}/hearts-white.svg`,
  calendar: `${astroIconFolderPath}/calendar.svg`,
  contacts: `${astroIconFolderPath}/contacts.svg`,
  close: `${astroIconFolderPath}/cross.svg`,
  bride: `${astroIconFolderPath}/bride.svg`,
  bridegroom: `${astroIconFolderPath}/bridegroom.svg`,
  people_two: `${astroIconFolderPath}/people-two.svg`,
  saturn_card: `${astroIconFolderPath}/saturn-card.svg`,
  delete: `${astroIconFolderPath}/bin.svg`,
  pencil: `${astroIconFolderPath}/pencil.svg`,
  call: `${astroIconFolderPath}/phone.svg`,
  vcard: `${astroIconFolderPath}/vcard-image.svg`,
  astrologerPhoto: `${astroIconFolderPath}/astrologer-photo.svg`,
  funnel: `${astroIconFolderPath}/filter-funnel.svg`,
  diamondWhite: `${astroIconFolderPath}/diamond-white.svg`,
  greenDot: `${astroIconFolderPath}/green-dot.svg`,
  checkVerified: `${astroIconFolderPath}/check-verified.svg`,
  IC_VIEWERS: `${astroIconFolderPath}/viewers.svg`,
  iconPlus: `${astroIconFolderPath}/plus.svg`,
  hearts_two: `${astroIconFolderPath}/hearts-two.svg`,
  arrow_left: `${astroIconFolderPath}/arrow-left.svg`,
  IC_BADGE: `${astroIconFolderPath}/badge.svg`,
  IC_FLAG: `${astroIconFolderPath}/flag.svg`,
  IC_CALENDAR: `${astroIconFolderPath}/calendar-black.svg`,
  IC_BRIEFCASE: `${astroIconFolderPath}/briefcase-3d.svg`,
  IC_LANGUAGES: `${astroIconFolderPath}/language-letters.svg`,
  IC_ERROR: `${astroIconFolderPath}/error.svg`,

  // city contest
  cityVideo:
    "https://media.chingari.io/uploads/notifications/82fd3bd7-3590-422f-905d-805e54728a02_.mp4",
  fullscreen: "/assets/icons/fullscreen.svg",
};

const REVIEW_POPUP_ICONS: { [keys: string]: string } = {
  REVIEW_IC_STAR: "/assets/icons/loginFlow/ReviewPopup/star-icon.svg",
  REVIEW_IC_STAR_golden:
    "/assets/icons/loginFlow/ReviewPopup/star-golden-icon.svg",
  REVIEW_IC_BACK_BLACK:
    "/assets/icons/loginFlow/ReviewPopup/back-black-Icon.svg",
};

export const RIGHT_MARK_ICON =
  "/assets/icons/loginFlow/ReviewPopup/right-mark.svg";

// Toast Icons---->>
export const CLOSE_ICON = "/assets/icons/close-icon.svg";
export const TOAST_ICON: { [keys: string]: string } = {
  SUCCESS: "/assets/icons/ToastIcon/toast-success.svg",
  ERROR: "/assets/icons/ToastIcon/toast-error.svg",
  WARNIG: "/assets/icons/ToastIcon/toast-warning.svg",
};

// Gender popup Image---->>
const MALE_IMG = "/assets/icons/loginFlow/GenderSelectPopup/male.png",
  FEMALE_IMG = "/assets/icons/loginFlow/GenderSelectPopup/female.png",
  OTHER_IMG = "/assets/icons/loginFlow/GenderSelectPopup/other.png";

// Language Select popup-->
export const IC_RADIO_TICK =
  "/assets/icons/loginFlow/LanguagePopup/radio-tick.svg";

// Login popup-->
export const LOGIN_FLOW_ICONS = {
  IC_PHONE: "/assets/icons/loginFlow/phone.svg",
  IC_GOOGLE: "/assets/icons/loginFlow/google.svg",
};

//! Astro LOGO-->>
export const ASTRO_LOGO_NAME = "/assets/icons/astro-logo-with-name.webp";
export const ASTRO_LOGO_IMG = "/assets/icons/astro-logo.webp";

export const IC_BACK = "/assets/icons/loginFlow/back.svg";
export const IC_CLOSE = "/assets/icons/loginFlow/close.svg";

// Calling Popup Icons---->>
export const CALLING_POPUP_ICONS: { [keys: string]: string } = {
  CALLING_ICON: "/assets/icons/CallingPopup/calling-icon.svg",
};

// Feed Call Buttons-------->>
export const Feed_CALL_BUTTON_ICONS: { [keys: string]: string } = {
  VIDEO_CALLING_ICON: "/assets/icons/CallFeed/video-calling-icon.svg",
  DIAMOND_ICON: "/assets/icons/CallFeed/diamond.svg",
  MUTE_ICON: "/assets/icons/CallFeed/mute-icon.svg",
  MUTE_ICON_WHITE: "/assets/icons/CallFeed/mute-icon-white.svg",
  CLOSE_ICON: "/assets/icons/CallFeed/close-icon.svg",
  CLOSE_ICON_SMALL: "/assets/icons/CallFeed/close-icon-small.svg",
};

// Profile Card Images---------->>
export const DIAMOND_ICON_PLAIN = "/assets/icons/diamond-plain.svg";

// Room Feed---->>
export const IC_BACK_ICON = "/assets/icons/RoomFeed/back-icon.svg";

// BuyPopup---->>
export const DIAMOND_ICON_BLUE = "/assets/icons/Buypopup/diamond-icon-blue.svg";
export const DIAMOND_ICON_PINK = "/assets/icons/Buypopup/diamond-icon-pink.svg";
export const RUPEE_ICON = "/assets/icons/Buypopup/rupee-icon.svg";
export const CLOSE_ICON_GRAY = "/assets/icons/Buypopup/close-icon-gray.svg";
// InsufficientBalancePopup------->>
export const INSUFFICIENT_BALANCE_ICON =
  "/assets/icons/insufficientBalancePopup/insufficient-balance-icon.png";

export const RUPEE_WHITE_ICON = "/assets/icons/rupee-icon-white.svg",
  RUPEE_ORANGE_ICON = "/assets/icons/rupee-icon-orange.svg",
  RUPEE_PURPLLE_ICON = "/assets/icons/rupee-icon-purplle.svg";

export const COPY_ICON = "/assets/icons/copy-icon.svg";
export {
  CHINGARI_LOGO,
  IC_VIEWERS,
  IC_CHAT,
  IC_EYE,
  IC_GIFT,
  IC_PLUS,
  IC_ERROR,
  IC_GIFT_WHITE,
  IC_CHAT_WHITE,
  IC_CROSS,
  IC_CALL,
  IC_CALL_FREE,
  IC_FIRE_ORANGE,
  IC_MUTE,
  IC_UNMUTE,
  IC_VCARD,
  LEVEL_ICON,
  PAGE_LINKS_ICONS,
  ERROR_IMAGE,
  NO_DATA,
  SOCIAL_BTN_APP_STORE,
  SOCIAL_BTN_ANDROID,
  SOCIAL_BTN_APP_GALARY,
  SOCIAL_BTN_PLAY_STORE,
  NETWORK_ERROR_IMAGE,
  DOWNLOAD_ICONS,
  DEFAULT_AVATAR,
  ROOM_CONTAINER,
  DEFAULT_USER_AVATAR,
  CATEGORY_ICONS,
  FILTER_ICON,
  SOME_IMG,
  REVIEW_POPUP_ICONS,
  MALE_IMG,
  OTHER_IMG,
  FEMALE_IMG,
};
