export const Config = {
  Cdn_Url: process.env.NEXT_PUBLIC_CHINGARI_CDN_URL || "",
  BACKEND_URL: process.env.NEXT_PUBLIC_WEB_BFF_SERVER || "",
  CDN_URL: process.env.NEXT_PUBLIC_CDN_URL || "",
  CHINGARI_HOME_URL: process.env.NEXT_PUBLIC_CHINGARI_HOME_URL || "",
  S3_URL: process.env.NEXT_PUBLIC_S3_URL || "",
  GA_MESUREMENT_ID: process.env.NEXT_PUBLIC_GA_MESUREMENT_ID || "",
  Backend_Url: process.env.NEXT_PUBLIC_WEB_BFF_SERVER || "",
  Event_Url: process.env.NEXT_PUBLIC_CHINGARI_EVENT_SERVICE || "",
  NEXT_PUBLIC_CHINGARI_HOST_URL:
    process.env.NEXT_PUBLIC_CHINGARI_HOST_URL || "",
  PRIVATE_CALL_URL: process.env.NEXT_PUBLIC_PRIVATE_CALL_URL || "",
  TRTC_SDK_ID: process.env.NEXT_PUBLIC_TRTC_SDK || "",
};
