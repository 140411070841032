// install app
export const INSTALL_APP_URL = `https://chingari.onelink.me/wYNT/c7cc8ee3">Download`;

// share popup component
export const BASE_SHARE_URL = "https://chingari.io";
export const SHARE_MESSAGE = `For more such entertaining videos, download Chingari - India’s No.1 short video app.`;
export const APP_SHARE_URL = {
  FACEBOOK: "https://facebook.com/sharer/sharer.php?u=",
  WHATSAPP: "whatsapp://send?text=",
  PINTEREST: "http://pinterest.com/pin/create/link/?url=",
  TWITTER: "https://twitter.com/intent/tweet?url=",
};
export const PROFILE_BASE_URL = "https://media.chingari.io/apipublic";
